<script setup lang="ts"></script>

<template>
  <VContainer>
    <VRow justify="center">
      <VCol>
        <slot />
      </VCol>
    </VRow>
  </VContainer>
</template>
